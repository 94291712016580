import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./components/Main";
import ElectronicPaySlip from "./components/pmax/ElectronicPaySlip";
import EmployeeHandbook from "./components/pmax/EmployeeHandbook";
import EmployeePerformance from "./components/pmax/EmployeePerformance";
import PmaxHomePage from "./components/pmax/PmaxHomePage";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { configuration } from "./config/config";
import EpaySlip from "./components/pmax/EpaySlip";
import Eperformance from "./components/pmax/Eperformance";
import Ehandbook from "./components/pmax/Ehandbook";
import Alldownloads from "./components/pmax/Alldownloads";
import ThankYou from "./components/pmax/ThankYou";
import ThankYouAlternate from "./components/pmax/ThankYouAlternate";
import Criteria from "./components/pmax/components/Criteria";
import Funding from "./components/pmax/components/Funding";
import WorkshopInformation from "./components/pmax/components/WorkshopInformation";
import WorkshopTimeslot from "./components/pmax/components/WorkshopTimeslot";

axios.interceptors.request.use((c) => {
  let header = {
    Accept: "application/json",
  };
  c.url = `${configuration.strapiURL}` + c.url;
  c.headers = header;
  return c;
});

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/ccp"
            render={() => <Redirect to="/ccp/homepage" />}
          />
          <Route
            exact
            path="/ccp/homepage"
            render={(props) => <PmaxHomePage {...props} />}
          />
          <Route
            exact
            path="/ccp/criteria"
            render={(props) => <Criteria {...props} />}
          />
          <Route
            exact
            path="/ccp/funding"
            render={(props) => <Funding {...props} />}
          />
          <Route
            exact
            path="/ccp/workshop-information"
            render={(props) => <WorkshopInformation {...props} />}
          />
          <Route
            exact
            path="/ccp/workshop-timeslot"
            render={(props) => <WorkshopTimeslot {...props} />}
          />
          <Route
            exact
            path="/pmax/payslip"
            render={(props) => <ElectronicPaySlip {...props} />}
          />
          <Route
            exact
            path="/pmax/performance"
            render={(props) => <EmployeePerformance {...props} />}
          />
          <Route
            exact
            path="/pmax/handbook"
            render={(props) => <EmployeeHandbook {...props} />}
          />
          <Route
            path="/pmax/epayslip"
            render={(props) => <EpaySlip {...props} />}
          />
          <Route
            path="/pmax/ehandbook"
            render={(props) => <Ehandbook {...props} />}
          />
          <Route
            path="/pmax/eperformance"
            render={(props) => <Eperformance {...props} />}
          />
          <Route
            path="/pmax/downloadAll"
            render={(props) => <Alldownloads {...props} />}
          />
          <Route
            path="/pmax/thankyou"
            render={(props) => <ThankYou {...props} />}
          />
          <Route
            path="/pmax/thankyou-performance"
            render={(props) => <ThankYouAlternate {...props} />}
          />
          <Route
            path="/pmax/thankyou-handbook"
            render={(props) => <ThankYouAlternate {...props} />}
          />
          <Route
            path="/pmax/thankyou-payslip"
            render={(props) => <ThankYouAlternate {...props} />}
          />
          <Route path="/" render={(props) => <Main {...props} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
