import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CCPFooter from "./CCPFooter";
import CCPNavbar from "./CCPNavbar";
import imageTeam from "../../../asset/pmax/team.png";

const Criteria = () => {
  return (
    <>
      <CCPNavbar />
      <Container fluid className="pmax-slip-benefit pmax-home-detail">
        <Container>
          <Row>
            <Col md={12}>
              <h6>CCP is perfect for you if: </h6>
              <ul>
                <li>Your company is Registered or incorporated in Singapore</li>
                <li>
                  Company has an employment size of not more than 200, OR annual
                  sales turnover of not more than S$100 million
                </li>
                <li>
                  Able to offer full-time employment that is at least a 1 year
                  contract/permanent, with a minimum fixed salary amount of
                  $2700/month.
                </li>
                <li>
                  Commit to the CCP training arrangements for the mid­career
                  individuals that they have selected
                </li>
                <li>
                  Be committed to work with WSG or its appointed partners on the
                  necessary administrative matters related to the programmes
                </li>
              </ul>
            </Col>
            <Col md={12}>
              <h6>The newly hired PMET must be: </h6>
              <ul>
                <li>A Singapore Citizen or Permanent Resident</li>
                <li>Minimum 21 years old</li>
                <li>
                  Been employed for less than 3 months from the date of
                  employment with the SME
                </li>
                <li>
                  Graduated or completed National Service for at least 2 years,
                  whichever is later
                </li>
                <li>
                  Has Career Conversion (significant change in job scope) OR is
                  from a Non-SME/MNC company
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="section-below-detail">
              <div>
                We have a limited number of slots available and request that
                only applicants who are serious about business growth apply.
                <br />
                <br />
                To register for training sessions, click below and choose the
                best time for you.
              </div>
              <div>
                <Button className="hero-image-cta" varient="danger">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://forms.office.com/r/DFM77T4KNq"
                  >
                    REGISTER YOUR TEAM
                  </a>
                </Button>
                <Button className="hero-image-cta ml-2" varient="danger">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="https://admin.crm.asme.org.sg/uploads/CCP_Factsheet_e26e6e02e8.pdf"
                  >
                    Download CCP Factsheet
                  </a>
                </Button>
              </div>
              
            </Col>
            <Col md={6} className="handbook-hero-img">
              <img src={imageTeam} alt="team"></img>
            </Col>
          </Row>
        </Container>
      </Container>

      <CCPFooter />
    </>
  );
};

export default Criteria;
