import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CCPFooter from "./CCPFooter";
import CCPNavbar from "./CCPNavbar";
import imagePeople from "../../../asset/pmax/People.png";

const Funding = () => {
  return (
    <>
      <CCPNavbar />
      <Container>
        <Row>
          <Col md={6}>
            <h1>SMEs Get Grants (Salary Support)</h1>
            <table cellPadding={15} border="1">
              <tr>
                <th>CCP Funding from WSG</th>
                <th>
                  Current disbursement (For new PMETs starting CCP on 1 Apr 2024
                  onwards)
                </th>
              </tr>
              <tr>
                <td>
                  Salary Support for New Hires on CCPs
                  <br />
                  <br />
                  (for PMETs)
                </td>
                <td>
                  Standard Rate: Up to <b>70% of Monthly Salary</b> (capped at
                  $5,000 for funding per month during training period)
                  <br />
                  <br />
                  Enhanced Rate: Up to <b>90% of Monthly Salary</b> (capped at
                  $7,500 for funding per month during training and retention
                  period)
                </td>
              </tr>
              <tr>
                <td>Salary Support Period</td>
                <td>
                  Up to <b>3 months</b>
                </td>
              </tr>
            </table>
          </Col>
          <Col md={6}>
            <img
              style={{
                height: "500px"
              }}
              src={imagePeople}
              alt="people"
            ></img>
          </Col>
        </Row>
        <Container>
          <h1 className="mt-4">
            Eligibility for <b>Enhanced Rate</b>
          </h1>
          <ul>
            <li>
              ●<b> Mature worker</b> – defined as 40 years old and above
            </li>
            <li>
              ●<b> Long Term Unemployed (LTU)</b> – defined as being unemployed
              and actively seeking employment for six months or more
            </li>
          </ul>
        </Container>
      </Container>

      <CCPFooter />
    </>
  );
};

export default Funding;
