import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PmaxLogo from "../../../asset/icons/CCPLogo.png";

const CCPNavbar = () => {
  const history = useHistory();

  const navigateToLink = (link) => {
    history.push(link);
  };

  return (
    <Navbar expand="lg">
      <Container className="ccp-navbar-container">
        <Navbar.Brand>
          <div
            onClick={() => navigateToLink("/ccp/homepage")}
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            className="top-bar"
          >
            <img src={PmaxLogo} height="60px" alt="ccp"></img>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          style={{
            justifyContent: "end",
          }}
          className="p-2"
          id="basic-navbar-nav"
        >
          <Nav
            style={{
              alignContent: "center",
              gap: "1rem",
            }}
            className="me-auto"
          >
            <Button
              onClick={() => navigateToLink("/ccp/criteria")}
              className="navbar-tab"
              variant="outline-primary"
            >
              CCP Criteria
            </Button>
            <Button
              onClick={() => navigateToLink("/ccp/funding")}
              className="navbar-tab"
              variant="outline-primary"
            >
              Funding
            </Button>
            <Button
              onClick={() => navigateToLink("/ccp/workshop-information")}
              className="navbar-tab"
              variant="outline-primary"
            >
              Workshop Information
            </Button>
            <Button
              onClick={() => navigateToLink("/ccp/workshop-timeslot")}
              className="navbar-tab"
              variant="outline-primary"
            >
              Workshop
            </Button>
            <Button
              onClick={() => navigateToLink("/")}
              className="navbar-tab text-danger border-danger ccp-home-button"
              variant="outline-primary"
            >
              ASME - Homepage
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CCPNavbar;
