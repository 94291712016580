import React, { Component } from "react";
import {
  Accordion,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Switch, Route, NavLink } from "react-router-dom";
import { FaClock, FaPhoneAlt, FaAngleRight, FaAngleDown } from "react-icons/fa";
import axios from "axios";
import $ from "jquery";

import "../stylesheets/common.css";

import Logo from "./../asset/icons/asme-logo.png";
import HomePage from "./HomePage";
import Footer from "./Footer";
import Events from "./Events";
import NewsRoom from "./NewsRoom";
import ResourceCenter from "./ResourceCenter";
import AboutUs from "./AboutUs";
import PublicServiceAnnouncement from "./PublicServiceAnnouncement";
import FeaturedMembers from "./FeaturedMember";
import Article from "./Article";
import PrivacyPolicy from "./PrivacyPolicy";
import PrivacyStatement from "./PrivacyStatement";
import Awards from "./Awards";
import Membership from "./Membership";
import Error from "./Error";
import Contact from "./Contact";
import Features from "./Features";

const getBannerAdvertisement = () => {
  return axios.get(`/main-banner-advertisement`).then((res) => {
    return res.data;
  });
};

const getFooterQuickLinks = () => {
  return axios.get(`/footer-quick-links`).then((res) => {
    return res.data;
  });
};

const getResourceValue = () => {
  return axios.get(`/resource-centers`).then((res) => {
    return res.data
      ?.filter((resource) => !resource?.hide)
      ?.sort((a, b) => b?.id - a?.id);
  });
};

const getLoginButtonURL = () => {
  return axios.get(`/homepage-login-button-url`).then((res) => {
    return res.data;
  });
};

export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: null,
      quickLinks: null,
      resource: null,
      selected: 0,
      loginButtonURL: "https://members.asme.org.sg/",
    };
    this.hideDropdown = this.hideDropdown.bind(this);

    this.props.history.listen((location, action) => {
      if ($("#basic-navbar-nav").hasClass("show")) {
        // on route change
        document.getElementById("toggleButton").click();
      }
    });
  }

  componentDidMount = async () => {
    const bannerData = await getBannerAdvertisement();
    if (bannerData) {
      this.setState({
        banner: bannerData,
      });
    }

    const quickLinks = await getFooterQuickLinks();
    if (quickLinks) {
      this.setState({
        quickLinks,
      });
    }

    const resource = await getResourceValue();
    if (resource) {
      this.setState({
        resource,
      });
    }

    const loginButtonURL = await getLoginButtonURL();
    if (loginButtonURL) {
      this.setState({
        loginButtonURL: loginButtonURL.URL,
      });
    }

    $(".rsc").hover(function () {
      if (window.innerWidth > 991) {
        $(".dropdown-content").css("display", "block");
      }
    });

    $(".dropdown-content").hover(function () {
      $(".dropdown-content").css("display", "block");
    });

    $(".dropdown-content").mouseleave(function () {
      $(".dropdown-content").css("display", "none");
    });

    $(".other").hover(function () {
      $(".dropdown-content").css("display", "none");
    });
  };

  hideDropdown = () => {
    $(".dropdown-content").css("display", "none");
  };

  render() {
    return (
      <Container fluid>
        <Container className="banner">
          <div className="banner-ph">
            <FaPhoneAlt></FaPhoneAlt> Phone: 6513 0388
          </div>
          <div className="banner-hours">
            <FaClock></FaClock> Opening Hours: Mon - Fri 9:00 am - 6:00 pm
          </div>
        </Container>
        <Container fluid className="navbar-container">
          <Container style={{ maxWidth: "1300px" }}>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <img src={Logo} width="60" alt="ASME Logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                id="toggleButton"
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto asme-navbar">
                  <NavLink exact to="/" className="other asme-nav-item">
                    Home
                  </NavLink>
                  <NavLink to="/aboutus" className="other asme-nav-item">
                    About Us
                  </NavLink>
                  <NavLink to="/Membership" className="other asme-nav-item">
                    Membership
                  </NavLink>
                  <NavLink to="/events" className="other asme-nav-item">
                    Events
                  </NavLink>
                  <NavLink
                    to="/businesscentre"
                    onClick={(e) => e.preventDefault()}
                    className="rsc mobile"
                  >
                    <Accordion>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="1"
                        variant="link"
                      >
                        Business Centre <FaAngleDown></FaAngleDown>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <>
                          {this.state.resource &&
                            this.state.resource.map((res, index) => {
                              return (
                                <Col
                                  key={res.id}
                                  md={12}
                                  className="display-flex"
                                >
                                  <Col
                                    md={12}
                                    className="hover-title parent"
                                    onClick={(e) =>
                                      this.setState({ selected: index })
                                    }
                                  >
                                    <NavLink
                                      to={{
                                        pathname:
                                          "/businesscentre/" + res?.slug,
                                        data: res,
                                      }}
                                      onClick={(e) => this.hideDropdown()}
                                    >
                                      <div className={"hover-item"}>
                                        <FaAngleRight /> {res.title}
                                      </div>
                                    </NavLink>
                                  </Col>
                                </Col>
                              );
                            })}
                        </>
                      </Accordion.Collapse>
                    </Accordion>
                  </NavLink>
                  <NavLink
                    to="/businesscentre"
                    onClick={(e) => e.preventDefault()}
                    className="rsc asme-nav-item"
                  >
                    Business Centre
                  </NavLink>
                  {/* <NavLink
                    to="/featuredMembers"
                    className="other asme-nav-item"
                  >
                    Featured Members
                  </NavLink> */}
                  <NavLink to="/Awards" className="other asme-nav-item">
                    Awards
                  </NavLink>
                  <a href={"https://asme.org.sg/newsroom"} className="other asme-nav-item">
                    Newsroom
                  </a>
                  {/* <NavLink to="/features" className="other asme-nav-item">
                    Features
                  </NavLink> */}
                  {/* <NavLink to="/publicserviceannouncement" className="other asme-nav-item">Public Service Announcement</NavLink> */}
                  {/* <NavLink to="/Contact" className="other asme-nav-item">
                    Contact
                  </NavLink> */}
                  <a
                    style={{
                      backgroundColor: "#002E5D",
                      color: "#FFF",
                      width: "100.2px",
                      height: "45.56px",
                      justifyContent: "center",
                    }}
                    href={this.state.loginButtonURL}
                    className="asme-nav-item"
                  >
                    Login
                  </a>
                </Nav>
              </Navbar.Collapse>
              {/* <FaSearch className="nav-search"></FaSearch> */}
            </Navbar>
            <div className="dropdown-content">
              <Row className="display-block">
                {this.state.resource &&
                  this.state.resource.map((res, index) => {
                    return (
                      <Col key={res.id} md={12} className="display-flex">
                        <Col
                          md={12}
                          className="hover-title parent"
                          onClick={(e) => this.setState({ selected: index })}
                        >
                          <NavLink
                            to={{
                              pathname: "/businesscentre/" + res?.slug,
                              data: res,
                            }}
                            onClick={(e) => this.hideDropdown()}
                          >
                            <div className={"hover-item"}>
                              <FaAngleRight /> {res.title}
                            </div>
                          </NavLink>
                        </Col>
                      </Col>
                    );
                  })}
              </Row>

              <Col md={12} className="display-flex p-0">
                <Col
                  md={12}
                  className="hover-title parent"
                  onClick={(e) => this.setState({ selected: 0 })}
                >
                  <NavLink
                    to={{
                      pathname:
                        "/article/newsroom/277/2024-09-30-apple-iphone-16-review--a-bigger-leap--but-not-quite-enough",
                    }}
                    onClick={(e) => this.hideDropdown()}
                  >
                    <div className={"hover-item"}>
                      <FaAngleRight /> Product Reviews
                    </div>
                  </NavLink>
                </Col>
              </Col>
            </div>
          </Container>
        </Container>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <HomePage banner={this.state.banner} {...props} />
            )}
          ></Route>
          <Route
            exact
            path="/events"
            render={(props) => <Events {...props} />}
          ></Route>
          <Route
            exact
            path="/newsroom"
            render={(props) => <NewsRoom {...props} />}
          ></Route>
          <Route
            exact
            path="/features"
            render={(props) => <Features {...props} />}
          />
          <Route
            exact
            path="/features/:slug"
            render={(props) => <Features {...props} />}
          />
          <Route
            exact
            path="/features/:slug/:id"
            render={(props) => <Features {...props} />}
          />
          <Route
            exact
            path="/businesscentre/:slug"
            render={(props) => <ResourceCenter {...props} />}
          ></Route>
          <Route
            exact
            path="/aboutus"
            render={(props) => <AboutUs {...props} />}
          ></Route>
          <Route
            exact
            path="/aboutus/:slug"
            render={(props) => <AboutUs {...props} />}
          ></Route>
          <Route
            exact
            path="/publicserviceannouncement"
            render={(props) => <PublicServiceAnnouncement {...props} />}
          ></Route>
          <Route
            exact
            path="/featuredMembers"
            render={(props) => <FeaturedMembers {...props} />}
          ></Route>
          <Route
            exact
            path="/termofuse"
            render={(props) => <PrivacyPolicy {...props} />}
          ></Route>
          <Route
            exact
            path="/privacystatement"
            render={(props) => <PrivacyStatement {...props} />}
          ></Route>
          <Route
            exact
            path="/article/:type/:id"
            render={(props) => <Article {...props} />}
          ></Route>
          <Route
            exact
            path="/article/:type/:id/:title"
            render={(props) => <Article {...props} />}
          ></Route>
          <Route
            path="/event/:id/:title"
            render={(props) => <Article {...props} />}
          ></Route>
          <Route
            exact
            path="/Awards"
            render={(props) => <Awards {...props} />}
          ></Route>
          <Route
            exact
            path="/Membership"
            render={(props) => <Membership {...props} />}
          ></Route>
          <Route
            exact
            path="/signup"
            render={(props) => <Membership {...props} />}
          ></Route>
          <Route
            exact
            path="/Contact"
            render={(props) => <Contact {...props} />}
          ></Route>
          <Route path="/" render={(props) => <Error {...props} />}></Route>
        </Switch>
        <Container fluid>
          <Footer quickLinks={this.state.quickLinks}></Footer>
        </Container>
      </Container>
    );
  }
}
