import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import PmaxLogo from "../../../asset/icons/CCPLogo.png";
import IconLocation from "../../../asset/icons/icon-location.png";
import IconPhone from "../../../asset/icons/icon-phone.png";
import IconMail from "../../../asset/icons/icon-mail.png";
import ImageFacebook from "../../../asset/icons/facebook.png";

const CCPFooter = () => {
  const navigateToSocialMedia = (media) => {
    if (media === "fb") {
      window.open(
        "https://facebook.com//SG.ASME",
        "_blank" // <- This is what makes it open in a new window.
      );
    } else {
      window.open(
        "https://instagram.com/asmesg",
        "_blank" // <- This is what makes it open in a new window.
      );
    }
  };

  return (
    <>
      <Container fluid className="pmax-footer">
        <Container>
          <Row>
            <Col md={4}>
              <img
                src={PmaxLogo}
                alt="pmax"
                width="80%"
                className="footer-logo"
              />
            </Col>
            <Col md={4} className="footer-contact">
              <div className="display-flex">
                <img src={IconLocation} height="24px" alt="Location icon" />
                <div>
                  167, Jalan Bukit Merah
                  <br />
                  #03-13
                  <br /> Singapore 150167
                  <br />
                </div>
              </div>
              <div className="display-flex">
                <img src={IconPhone} height="24px" alt="Location icon" />
                <div>6015 0061 / 6015 0062 / 6015 0063</div>
              </div>
              <div className="display-flex">
                <img src={IconMail} height="24px" alt="Location icon" />
                <div>ccp@asme.org.sg</div>
              </div>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={4}>
                  <img
                    onClick={(e) => navigateToSocialMedia("fb")}
                    src={ImageFacebook}
                    alt="facebook"
                    className="img-adjust"
                    width="100%"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="pmax-home-footer">
        <Row>
          <Col md={12}>
            <h6>Copyright</h6>
          </Col>
          <Col md={12} className="footer-privacy-policy">
            <h6>
              <div>Privacy Policy </div> | <div> Terms of Services</div>
            </h6>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CCPFooter;
