import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Breadcrumb,
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ResourceCenterBanner from "../asset/banner/resource-center.png";
import "../stylesheets/resourcecenter.css";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Loading from "./Loading";
const getAboutUs = (id) => {
  return axios.get(`/expand-collapse-rcs?id=${id}`).then((res) => {
    return res.data;
  });
};
const getData = (id) => {
  return axios.get(`/resource-centers?slug=${id}`).then((res) => {
    return res.data;
  });
};
export default function ResourceCenter(props) {
  const [sidePanelArray, setSidePanelArray] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [newData, setNewData] = useState(null);
  useEffect(() => {
    if (props.location && props.location.data) {
      setNewData(props.location.data);
    } else if (props && props.match && props.match.params) {
      getData(props.match.params?.slug).then((res) => {
        setNewData(res?.[0]);
        getAboutUs(res?.[0]?.rightbar?.[0]?.id).then((res) => {
          setSidePanelArray(res[0].expand_collapse_rcs_subelements);
        });
      });
    }
    window.scrollTo(0, 0);
    if (
      props &&
      props.location &&
      props.location.data &&
      props.location.data.rightbar
    ) {
      getAboutUs(props.location.data.rightbar[0].id).then((res) => {
        setSidePanelArray(res[0].expand_collapse_rcs_subelements);
      });
    }
  }, [props]);
  const updateTab = (index) => {
    setActiveIndex(index + 1);
    getAboutUs(newData.rightbar[index].id).then((res) => {
      setSidePanelArray(res[0].expand_collapse_rcs_subelements);
    });
    setSidePanelArray();
  };
  if (newData) {
    return (
      <Container fluid className="Events-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Business Centre | ASME </title>
          <link rel="canonical" href={window.location.origin} />
          <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
          </noscript>
        </Helmet>
        <Container fluid className="banner-container">
          <img
            src={ResourceCenterBanner}
            alt="Event Banner"
            width="100%"
            className="banner-img"
          ></img>
          <h1>Business Centre</h1>
        </Container>
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/">Home</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink
                  to="/businesscentre"
                  onClick={(e) => e.preventDefault()}
                >
                  Business Centre
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink
                  to="/businesscentre"
                  onClick={(e) => e.preventDefault()}
                >
                  {newData.title}
                </NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <Container className="member-container">
          <Row>
            <Col md={4} className="resource-navigation">
              <div>
                <h3>Business Centre</h3>
                <ul>
                  {newData.rightbar &&
                    newData.rightbar.map((items, index) => {
                      return (
                        <li
                          onClick={(e) => updateTab(index)}
                          className={
                            activeIndex === index + 1
                              ? "active sidebase-item"
                              : "sidebase-item"
                          }
                        >
                          {items.title}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </Col>
            <Col md={8}>
              {/* <Row> <Col md={12}><h2 className="resource-center-title">Starting a business in Singapore</h2></Col></Row> */}
              <Row>
                <Col md={12}>
                  <Accordion defaultActiveKey="0">
                    {sidePanelArray && sidePanelArray.length > 0
                      ? sidePanelArray
                          .sort((a, b) => (a.id > b.id ? 1 : -1))
                          .map((item, index) => {
                            return (
                              <Card>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    variant="link"
                                    eventKey={index === 0 ? "0" : index}
                                    className="accordin-title"
                                  >
                                    {item.Header} <FaAngleDown></FaAngleDown>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse
                                  eventKey={index === 0 ? "0" : index}
                                >
                                  <Card.Body>
                                    <ReactMarkdown linkTarget="_blank">
                                      {item.Description}
                                    </ReactMarkdown>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            );
                          })
                      : null}
                  </Accordion>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else {
    return <Loading></Loading>;
  }
}
