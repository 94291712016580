import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AboutUsBanner from '../asset/banner/aboutus-banner.png';
import ReactMarkdown from 'react-markdown';
import Loading from './Loading';
import axios from 'axios';
const getMemberShipData = () => {
    return axios.get(`/membership-v-2`).then(res => {
        return res.data;
    })
}
export default function Membership() {
    const [memberData, setmemberData] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0)
        getMemberShipData().then(res => {
            setmemberData(res);
        })
    }, [])
    if (memberData) {
        return (
            <Container fluid className="Events-container">
                <Container fluid className="banner-container">
                    <img src={AboutUsBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Membership</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item ><NavLink to="/">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item href="/resource-center">
                                <NavLink to="/Membership">Membership</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </Container>
                <Container className="internal-container membershp-details">
                    <ReactMarkdown>
                        {memberData.content ? memberData.content.replaceAll("](/", "](https://admin.asme.org.sg/") : null}
                    </ReactMarkdown>
                </Container>
            </Container>
        )
    } else {
        return <Loading></Loading>;
    }
}
