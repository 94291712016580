import { Carousel } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { configuration } from "../config/config";

const ImageAndVideosCarousel = ({ bannerCarousel, className }) => {
  const [index, setIndex] = useState(0);
  const videoRef = useRef(null);
  const timerRef = useRef(null);

  const isVideo = (url) => /\.(mp4|webm)$/.test(url);

  useEffect(() => {
    if (bannerCarousel.length === 0) return;

    const currentItem = bannerCarousel[index];

    if (isVideo(currentItem.url)) {
      videoRef.current.play().catch(console.error);
    } else {
      timerRef.current = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % bannerCarousel.length);
      }, 1500);
    }

    return () => clearTimeout(timerRef.current);
  }, [index, bannerCarousel]);

  return (
    <Carousel
      activeIndex={index}
      onSelect={setIndex}
      className={className}
      controls={true}
      indicators={true}
      pause={false}
      interval={null}
    >
      {bannerCarousel?.length > 0
        ? bannerCarousel.map((item) => (
            <Carousel.Item key={item.title || item.url}>
              {isVideo(item.url) ? (
                <video
                  ref={videoRef}
                  autoPlay
                  onEnded={() =>
                    setIndex(
                      (prevIndex) => (prevIndex + 1) % bannerCarousel.length
                    )
                  }
                  style={{ width: "100%", height: "auto" }}
                  muted
                >
                  <source
                    src={`${configuration.strapiURL}${item.url}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={`${configuration.strapiURL}${
                    item?.formats?.large?.url || item.url
                  }`}
                  alt={item.title}
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </Carousel.Item>
          ))
        : null}
    </Carousel>
  );
};

export default ImageAndVideosCarousel;
