import moment from "moment";

export const formatUrl = (title, date) => {
  const formatTitle = title
    .replace(/&/g, "and")
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/([a-z0-9])[^a-z0-9\s-]+([a-z0-9])/gi, "$1$2") // Remove special characters inside words
    .replace(/[^a-z0-9\s-]/g, "") // Remove any remaining non-alphanumeric characters except spaces and hyphens
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Condense multiple hyphens into one

  return date
    ? `${moment(date).format("YYYY-MM-DD")}-${formatTitle}`
    : formatTitle;
};