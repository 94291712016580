import React, { useState, useEffect } from 'react'
import { Breadcrumb, Col, Container, Row, Card, Button } from 'react-bootstrap';
import '../stylesheets/events.css';
import NewsRoomBanner from '../asset/banner/newsroom-banner.png';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { dateToTitleFormat } from '../utils/DateUtils';
import { configuration } from '../config/config';
import { Helmet } from 'react-helmet';
import Loading from './Loading';
import { FaTimesCircle } from 'react-icons/fa';
import { formatUrl } from '../utils/formatURL';
const getNews = (number) => {
    return axios.get(`/newsrooms?_sort=date:DESC&_limit=${number}`)
        .then(res => {
            return res.data;
        })
}
const getFilteredNews = (number, filter) => {
    return axios.get(`/newsrooms?_limit=${number}&type=${filter.replaceAll("&", "%26")}&_sort=date`)
        .then(res => {
            return res.data;
        })
}
export default function NewsRoom({ history }) {
    const [data, setData] = useState([]);
    const [numberOfTiles, setNumberOfTiles] = useState(9);
    const [noMoreToLoad, setNoMoreToLoad] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0)
        getNews(numberOfTiles)
            .then(res => {
                setData(res);
                if (res.length < 9) {
                    setNoMoreToLoad(false)
                } else {
                    setNoMoreToLoad(true)
                }
            })
    }, []);
    const updateTiles = () => {
        setNumberOfTiles(numberOfTiles + 9);
        if (filter) {
            getFilteredNews(numberOfTiles, filter).then(res => {
                setData(res)
                if (res.length < numberOfTiles + 9) {
                    setNoMoreToLoad(false)
                } else {
                    setNoMoreToLoad(true)
                }
            });
        } else {
            getNews(numberOfTiles + 9).then(res => {
                setData(res);
                if (res.length < numberOfTiles + 9) {
                    setNoMoreToLoad(false)
                } else {
                    setNoMoreToLoad(true)
                }
            })
        }
    }
    const [filter, setFiler] = useState(null);
    const readMore = (item) => {
        history.push({ pathname: `/article/newsroom/${item.id}/${formatUrl(item.title, item.date)}`, state: { item } });
    }
    const selectFilter = (value) => {
        window.scrollTo(0, 0);
        setFiler(value);
        //get Filtered Articles
        setData([]);
        getFilteredNews(numberOfTiles, value).then(res => {
            setData(res);
            if (res.length < 9) {
                setNoMoreToLoad(false)
            } else {
                setNoMoreToLoad(true)
            }
        });
    }
    const clearFilter = () => {
        setFiler(null);
        //get Product Again
        setNumberOfTiles(9)
        getNews(9)
            .then(res => {
                setData(res);
                if (res.length < 9) {
                    setNoMoreToLoad(false)
                } else {
                    setNoMoreToLoad(true)
                }
            })
        window.scrollTo(0, 0)
    }
    if (data && data.length > 0) {
        return (
            <Container fluid className="Events-container">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Newsroom | ASME </title>
                    <link rel="canonical" href={window.location.origin} />
                    <script type="text/javascript">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                    {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
                </Helmet>
                <Container fluid className="banner-container">
                    <img src={NewsRoomBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Newsroom</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <NavLink to="/">Home</NavLink>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <NavLink to="/newsroom">Newsroom</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Row>
                        <h2 className="news-room-header">Newsroom</h2>
                    </Row>
                </Container>
                <Container className="member-container">
                    <Row>
                        <Col md={12}>
                            {filter ? <Button variant="primary" className="newsroom-button">{filter} <FaTimesCircle onClick={(e) => clearFilter()}></FaTimesCircle> </Button> : null}
                        </Col>
                    </Row>
                    <Row>
                        {data.map((value, index) => {
                            return <Col md={4} className="event-container">
                                <Card className="event-card">
                                    {value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.large ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.large.url} /> :
                                        value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.medium ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.medium.url} /> :
                                            value && value.tileImage[0] && value.tileImage[0].formats && value.tileImage[0].formats.small ? <Card.Img onClick={(e) => readMore(value)} variant="top" src={configuration.strapiURL + value.tileImage[0].formats.small.url} /> : null}
                                    <div className="newsroom-date">{dateToTitleFormat(value.date, 'MMM DD, yyyy')}</div>
                                    <Card.Body>
                                        <Card.Text>
                                            <div className="event-title" onClick={(e) => readMore(value)}>{value.title}</div>
                                        </Card.Text>
                                        <Button variant="primary" className="newsroom-button" onClick={(e) => selectFilter(value.type)}>{value.type}</Button>
                                        <div className="psa-readmore" onClick={(e) => readMore(value)}>Read More</div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })}
                    </Row>
                    {noMoreToLoad && <Row className="load-more">
                        <Col md={12}>
                            <Button variant="primary" onClick={updateTiles}>Load More</Button>
                        </Col>
                    </Row>}
                </Container>
            </Container>
        )
    } else {
        return <Loading></Loading>
    }
}
