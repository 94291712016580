import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CCPFooter from "./CCPFooter";
import CCPNavbar from "./CCPNavbar";
import imgTraining from "../../../asset/pmax/img-training.png";

const WorkshopInformation = () => {
  return (
    <>
      <CCPNavbar />
      <Container fluid className="how-it-works">
        <>
          <h1>How It Works</h1>
          <Row>
            <Col md={6} className="handbook-hero-img">
              <img src={imgTraining} alt="people"></img>
            </Col>
            <Col md={6} className="">
              <ul>
                <li>
                  <div className="display-flex">
                    <h3> DAY 1: Module 1 - SME Environment</h3>
                  </div>
                  <div className="pl-4">
                    <b>PMET hires </b> will learn techniques to help them
                    acclimatise to their new SME working environment Time: 9am
                    to 6pm
                  </div>
                </li>
                <li>
                  <div className="display-flex">
                    <h3>
                      DAY 2: Module 2 - Organisation Structure & Functions
                    </h3>
                  </div>
                  <div className="pl-4">
                    <b>PMET hires </b> will learn foundational skills in various
                    key business functional areas necessary to operate
                    effectively in an SME environment
                    <br />
                    Time: 9am to 6pm
                  </div>
                </li>
                <li>
                  <div className="display-flex">
                    <h3>DAY 3: Module 3.1 - Onboarding</h3>
                  </div>
                  <div className="pl-4">
                    <b>
                      Joint session between PMETs and their SME supervisors{" "}
                    </b>
                    to facilitate interaction and alignment of work expectations
                    including
                    <br />
                    • Job description
                    <br />
                    • Job tasks and
                    <br />
                    • Expected deliverables
                    <br />
                    <br />
                    Time: 9am to 1pm
                  </div>
                </li>
                <li>
                  <div className="display-flex">
                    <h3>DAY 3: Module 3.2 - Onboarding (Continued) </h3>
                  </div>
                  <div className="pl-4">
                    <b>
                      Joint session between PMETs and their SME supervisors{" "}
                    </b>
                    to facilitate interaction and alignment of work expectations
                    <br />
                    • One-to-One session with Trainer
                    <br />
                    <br />
                    Time: 2pm to 6pm
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button className="hero-image-cta" varient="danger">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://forms.office.com/r/DFM77T4KNq"
                >
                  REGISTER YOUR TEAM
                </a>
              </Button>
              <Button className="hero-image-cta ml-2" varient="danger">
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="https://admin.crm.asme.org.sg/uploads/CCP_Factsheet_e26e6e02e8.pdf"
                >
                  Download CCP Factsheet
                </a>
              </Button>
            </Col>
          </Row>
        </>
      </Container>
      <CCPFooter />
    </>
  );
};

export default WorkshopInformation;
