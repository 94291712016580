import React, { useEffect, useState } from 'react'
import { Breadcrumb, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import AboutUsBanner from '../asset/banner/aboutus-banner.png';
import ReactMarkdown from 'react-markdown';
import Loading from './Loading';
import axios from 'axios';
import { Helmet } from 'react-helmet';
const getAwardData = () => {
    return axios.get(`/awards-v-2`).then(res => {
        return res.data;
    })
}
export default function Awards() {
    const [awardData, setawardData] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0)
        getAwardData().then(res => {
            setawardData(res);
        })
    }, [])
    if (awardData) {
        return (
            <>
            <Helmet>
            <script type="text/javascript">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '5216654018407133');
                    fbq('track', 'PageView');
                    fbq('track', 'ViewContent');`}
                    </script>
                    <noscript>
                    {`<img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
                    />`}
                    </noscript>
            </Helmet>
            <Container fluid className="Events-container">
                <Container fluid className="banner-container">
                    <img src={AboutUsBanner} alt="Event Banner" width="100%" className="banner-img"></img>
                    <h1>Awards</h1>
                </Container>
                <Container>
                    <Row>
                        <Breadcrumb>
                            <Breadcrumb.Item ><NavLink to="/">Home</NavLink></Breadcrumb.Item>
                            <Breadcrumb.Item href="/resource-center">
                                <NavLink target="_Blank" to="/Awards">Awards</NavLink>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                </Container>
                <Container className="internal-container membershp-details">
                    <ReactMarkdown>
                        {awardData.content ? awardData.content.replaceAll("](/", "](https://admin.asme.org.sg/") : null}
                    </ReactMarkdown>
                </Container>
            </Container></>
        )
    } else {
        return <Loading></Loading>;
    }
}
