import React from "react";
import { Container } from "react-bootstrap";
import CCPNavbar from "./CCPNavbar";

const timeTable = [
  {
    name: "January",
    dates: "9th to 11th <br /> 23rd to 25th",
  },
  {
    name: "February",
    dates: "6th to 8th <br /> 20th to 22nd",
  },
  {
    name: "March",
    dates: "5th to 7th <br /> 19th to 21st",
  },
  {
    name: "April",
    dates: "2nd to 4th <br /> 16th to 18th",
  },
  {
    name: "May",
    dates: "7th to 9th <br /> 28th to 30th ",
  },
  {
    name: "June",
    dates: "11th to 13th <br /> 25th to 27th",
  },
  {
    name: "July",
    dates: "9th to 11th <br /> 23rd to 25th",
  },
  {
    name: "August",
    dates: "6th to 8th <br /> 20th to 22nd",
  },
  {
    name: "September",
    dates: "3rd to 5th <br /> 17th to 19th",
  },
  {
    name: "October",
    dates: "1st to 3rd <br /> 15th to 17th",
  },
  {
    name: "November",
    dates: "5th to 7th <br /> 19th to 21st",
  },
  {
    name: "December",
    dates: "3rd to 5th <br /> 17th to 19th",
  },
];

const WorkshopTimeslot = () => {
  return (
    <>
      <CCPNavbar />
      <Container>
        <h1>3-day Workshop Schedule for 2024</h1>
        <table cellPadding={15} border="1">
          <caption>
            <p>
              <i>
                *subject to changes by ASME, and participating companies will be
                informed respectively
              </i>
            </p>
          </caption>

          <tr>
            <th>Month</th>
            <th>Dates (2024)</th>
          </tr>
          {timeTable.map((month) => (
            <tr key={month.name}>
              <td>{month.name}</td>
              <td dangerouslySetInnerHTML={{ __html: month.dates }}></td>
            </tr>
          ))}
        </table>
      </Container>
    </>
  );
};

export default WorkshopTimeslot;
