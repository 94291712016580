import React, { useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

import HomePageHero from "../../asset/pmax/home-hero.png";
import asmeLogo from "../../asset/icons/asme-logo.png";
import WSGLogo from "../../asset/icons/wsg.png";
import ssgLogo from "../../asset/icons/ssg.jpg";
import HumanResourceIcon from "../../asset/pmax/human-resources-svgrepo-com.svg";
import LeadershipIcon from "../../asset/pmax/leadership-svgrepo-com.svg";
import ProjectIcon from "../../asset/pmax/businessman-showing-a-project-sketch-svgrepo-com.svg";
import BusinessIcon from "../../asset/pmax/business-presentation-svgrepo-com.svg";
import OperationsIcon from "../../asset/pmax/networking-svgrepo-com.svg";
import CCPNavbar from "./components/CCPNavbar";
import CCPFooter from "./components/CCPFooter";

export default function PmaxHomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView
            &noscript=1" alt="facebook"/>`}
        </noscript>
      </Helmet>
      <CCPNavbar />
      <Container fluid className="hero-section-pax homepage">
        <Row>
          <Col md={6} className="handbook-hero-img">
            <img src={HomePageHero} width="70%" alt="pmax"></img>
          </Col>
          <Col md={6} className="hero-section-desc">
            <h1>New Possibilities with Reskilled Talents</h1>
            <h4>Grow your SME with mid-career PMETs</h4>
            <Button className="hero-image-cta" varient="danger">
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://forms.office.com/r/DFM77T4KNq"
              >
                REGISTER YOUR TEAM
              </a>
            </Button>
            <Button className="hero-image-cta ml-2" varient="danger">
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://admin.crm.asme.org.sg/uploads/CCP_Factsheet_e26e6e02e8.pdf"
              >
                Download CCP Factsheet
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid className="pmax-payslip-desc home-page-section">
        <Container>
          <Row>
            <Col md={12}>
              <h6 style={{ color: "#013220" }}>
                Reskill Professionals, Managers, Executives and Technicians
                (PMETs) to take on new job roles in SMEs to help them
                acclimatise to an SME working environment
              </h6>
            </Col>
            <Col md={12}>
              <div className="bottom-desc">
                SMEs form 99% of enterprises in Singapore and play an integral
                role in our economy. SMEs currently employ seven out of ten
                workers and contribute to half of Singapore's GDP
                <br />
                <br />
                As we adapt to the new normal, the right talent is key to
                support and sustain the business growth of SMEs
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="pmax-payslip-desc home-page-section">
        <Container>
          <Row>
            <Col md={12}>
              <h6 style={{ color: "#013220" }}>
                CCP for SME Executives Supports a Wide Spectrum of Job Roles in
                Areas such as:
              </h6>
            </Col>
            <Col md={12}>
              <div className="sme-executives-grid">
                <div>
                  <img width={100} src={HumanResourceIcon} alt="" />
                  <h3>Human Resource</h3>
                </div>
                <div>
                  <img width={100} src={LeadershipIcon} alt="" />
                  <h3>Leadership and Management</h3>
                </div>
                <div>
                  <img width={100} src={ProjectIcon} alt="" />
                  <h3>Project Management</h3>
                </div>
                <div>
                  <img width={100} src={BusinessIcon} alt="" />
                  <h3>Sales & Marketing, Business Development</h3>
                </div>
                <div>
                  <img width={100} src={OperationsIcon} alt="" />
                  <h3>Operations & Administration</h3>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="pmax-homepage-benefit pmax-homepage-clients">
        <Container>
          <h1 style={{ color: "#013220" }}>In Collaboration With</h1>
          <Row>
            <Col md={4}>
              <img src={asmeLogo} alt="clients" width="60%" />
            </Col>
            <Col md={4}>
              <img src={WSGLogo} alt="clients" width="60%" />
            </Col>
            <Col md={4}>
              <img src={ssgLogo} alt="clients" width="60%" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="pmax-homepage-benefit">
        <Container>
          <h1>UNLEASH THE POWER OF YOUR NEW HIRES TODAY</h1>
          <div>
            <Button className="hero-image-cta bottom-cta" varient="danger">
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://forms.office.com/r/DFM77T4KNq"
              >
                REGISTER YOUR TEAM
              </a>
            </Button>
            <Button className="hero-image-cta bottom-cta ml-2" varient="danger">
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://admin.crm.asme.org.sg/uploads/CCP_Factsheet_e26e6e02e8.pdf"
              >
                Download CCP Factsheet
              </a>
            </Button>
          </div>
        </Container>
      </Container>
      <CCPFooter />
    </>
  );
}
