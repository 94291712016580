import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Breadcrumb,
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import AboutUsBanner from "../asset/banner/aboutus-banner.png";
import "../stylesheets/resourcecenter.css";
import ReactMarkdown from "react-markdown";
import Loading from "./Loading";
import { Helmet } from "react-helmet";
const getAboutUs = () => {
  return axios.get(`/about-us-v-2-s?_sort=id`).then((res) => {
    return res.data;
  });
};
export default function AboutUs(props) {
  const [data, setData] = useState([]);
  const [sidePanelArray, setSidePanelArray] = useState([]);
  const [activeIndex, setActiveIndex] = useState("");

  const slug = props.match.params.slug;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAboutUs().then((res) => {
      setData(res);
      setSidePanelArray(res?.[0].about_uses);
    });
  }, []);

  useEffect(() => {
    if (slug) {
      setActiveIndex(slug);
      const activeSidePanel = data?.find(
        (sidePanel) => sidePanel?.slug === slug
      );
      setSidePanelArray(activeSidePanel?.about_uses);
    } else {
      setActiveIndex(data?.[0]?.slug);
      setSidePanelArray(data?.[0]?.about_uses);
    }
  }, [slug, data]);

  if (data && data.length > 0) {
    return (
      <>
        <Helmet>
          <script type="text/javascript">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '5216654018407133');
            fbq('track', 'PageView');
            fbq('track', 'ViewContent');`}
          </script>
          <noscript>
            {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=5216654018407133&ev=PageView&noscript=1"
            />`}
          </noscript>
        </Helmet>
        <Container fluid className="Events-container">
          <Container fluid className="banner-container">
            <img
              src={AboutUsBanner}
              alt="Event Banner"
              width="100%"
              className="banner-img"
            ></img>
            <h1>About Us</h1>
          </Container>
          <Container>
            <Row>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <NavLink to="/">Home</NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/resource-center">
                  <NavLink to="/aboutus">About Us</NavLink>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Container>
          <Container className="internal-container">
            <Row>
              <Col md={4} className="resource-navigation">
                <div>
                  <h3>Overview</h3>
                  <ul>
                    {data.map((items, index) => {
                      return (
                        <NavLink
                          className={
                            activeIndex === items?.slug ? "text-black" : ""
                          }
                          to={`/aboutus/${items?.slug}`}
                        >
                          <li
                            className={
                              activeIndex === items?.slug
                                ? "active sidebase-item"
                                : "sidebase-item"
                            }
                          >
                            {items.sidebarTitle}
                          </li>
                        </NavLink>
                      );
                    })}
                  </ul>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  <Col md={12}>
                    <Accordion defaultActiveKey="0">
                      {sidePanelArray && sidePanelArray.length > 0
                        ? sidePanelArray
                            .sort((a, b) => (a.id > b.id ? 1 : -1))
                            .map((item, index) => {
                              return (
                                <Card>
                                  <Card.Header>
                                    <Accordion.Toggle
                                      as={Card.Header}
                                      variant="link"
                                      eventKey={index === 0 ? "0" : index}
                                      className="accordin-title"
                                    >
                                      {item.title} <FaAngleDown></FaAngleDown>
                                    </Accordion.Toggle>
                                  </Card.Header>
                                  <Accordion.Collapse
                                    eventKey={index === 0 ? "0" : index}
                                  >
                                    <Card.Body>
                                      <ReactMarkdown>
                                        {item.details.replaceAll(
                                          "](/",
                                          "](https://admin.asme.org.sg/"
                                        )}
                                      </ReactMarkdown>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              );
                            })
                        : null}
                    </Accordion>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  } else {
    return <Loading></Loading>;
  }
}
